import { APIFilter } from '@/utils/api'

export default {
  async selectEstudioFinanciero (Vue, idestudioFinanciero) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idestudio_financiero', idestudioFinanciero)
    const resp = await Vue.$api.call('estudioFinanciero.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
